.container {
	height: 60px;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	width: 100%;
}

.navBar {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 0 4rem 0 1rem;
}

.route {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #19943c;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding-top: 15px;
}

.navBarRoute {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.017em;
	color: #0a0a0a;
}

.activitySection {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.notification {
	position: relative;
	padding: 0 18px 0 24px;
	cursor: pointer;

	.notificationCount {
		position: absolute;
		font-family: 'HelveticaNeue', sans-serif;
		font-weight: 600;
		font-size: 10px;
		background: #e00000;
		border-radius: 100%;
		height: 12px;
		width: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		top: 1px;
		right: 22px;
	}
}

.userImage {
	object-fit: cover;
}

.menu {
	display: flex;
	justify-content: center;
	align-self: center;
}

.menuItem {
	padding: 0.5rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin: 1rem;
}

.menuItem:hover {
	background-color: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}

.activeMenu {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 1rem;
}

.activeColor {
	background-color: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}

.activeColorText {
	padding: 0.5rem;
}

.subMenu {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.subMenuItem {
	padding: 0.5rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #424242;
	margin: 1rem;
}

.subMenuItem:hover {
	background: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}

.activeMenuItem {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	background-color: #fff;
	margin: 0 1rem;

	.activeItemColor {
		color: #0c4a1e;
		border-bottom: 3.5px solid #19943c;
		border-radius: 2px;
		padding-top: 10px;
		padding-bottom: 12px;
		cursor: pointer;
	}
}

a {
	text-decoration: none;
}

.account {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	// width: 100%;
}

.accountDetail {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	color: rgba(0, 40, 65, 0.8);
	padding-left: 9px;
}

.signOut {
	line-height: 140%;
	padding-left: 9px;
	color: #f71735;
}

.logOut {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
}

.containerMobile {
	height: 4rem;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	width: 100vw;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9;
	overflow: hidden;
	margin-bottom: 0;
}

.navBarMobile {
	display: flex;
	align-self: center;
	justify-content: space-between;
	padding: 0 2rem;
}

.logoMobile {
	// margin-top: 1rem;

	img {
		width: 150px;
	}

}

.mobileMenu {
	margin-top: 1rem;
	justify-content: end;
	align-items: center;
	color: #19943c;
}

.mobileMenuDiv {
	height: 80px;
	overflow-y: scroll;
	margin-top: 2rem;
}

.accountMobile {
	display: block;
	margin: 0.5rem 0 0 0.5rem;
}

.mobileMenuItem {
	margin-left: 0.8rem;
	// padding-bottom: -0.16rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.mobileMenuItem:hover {
	background-color: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}

.mobileSubMenu {
	padding: 0.8rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: -0.011em;
	color: #424242;
}

.eachMenu:hover {
	border-bottom: 2px solid #19943c;
	cursor: pointer;
}

.text {
	margin: 20px 0;
}

.swicth {
	display: flex;
	padding-top: 1rem;

	// justify-content: center;
	// align-items: center;
}

.swicthIcon {
	animation: bounce 3s infinite;
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(5px);
	}

	60% {
		transform: translateY(10px);
	}
}