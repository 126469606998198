.modalBody {
	width: 444px;
	border-radius: 12px;
	.background {
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.modalHeader {
		background: #19943c;
	}
}

.businessForm {
	width: 100% !important;
	// margin-top: 24px;
	margin-right: auto;
	padding: 0 24px;
	& > form {
		display: flex;
		flex-direction: column;
		padding-top: 24px;
		padding-bottom: 24px;
		height: 360px;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}

		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}

.linkDetails {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
	color: #ffffff;
}

.modalContent {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	padding: 0 24px;
	.modalDetails {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 12px;
		padding-top: 12px;
		border-bottom: 1px solid #e0e0e0;
	}
}

.modalFooter {
	padding: 12px 24px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: #f5f5f5;
	.cancel {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #0a0a0a;
		padding: 8px 10px;
		background: #ffffff;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		cursor: pointer;
	}
	.deactivate {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #ffffff;
		padding: 8px 12px;
		background: #19943c;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		cursor: pointer;
		margin-left: 16px;
	}

	.notClickable {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.011em;
		color: #9e9e9e;
		background: #ededed;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		margin-left: 16px;
		padding: 8px 12px;
	}
}
