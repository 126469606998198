.menuContent {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;

	.optionName {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		padding-left: 11px;
	}
}

.createLink {
	width: fit-content;
	height: 40px;
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	padding: 10px;
	cursor: pointer;

	.link {
		margin-right: 10px;
	}

	.mt1 {
		margin-top: 1rem;
	}
}

.businessActionButtons {
	display: flex;
	gap: 10px;
}

.mt0 {
	margin-top: 0.5rem;
}

.mt1 {
	margin-top: 2rem;
}

.dashbordContainer {
	margin: 40px 0px;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dashboardChart {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	height: 100%;
	padding: 24px;
}

.filterSubheading {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	cursor: pointer;
}

.filterBody {
	margin-top: 10px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	cursor: pointer;
}

.mb {
	margin-bottom: 1rem;
}

.detailsHeader {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

.detailsSubHeader {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

@media only screen and (max-width: 600px) {
	.dashbordContainer {
		margin: 0.5rem 0;
	}
}