.menuBody {
	position: relative;
	.menuIcon {
		cursor: pointer;
	}
	.menuContent {
		background: #ffffff;
		border: 1px solid #ededed;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		margin-top: 2px;
		position: absolute;
		z-index: 9;
		padding: 15px 10px 5px 15px;
		right: 2rem;
		width: 258px;
	}
}

.modalBody {
	width: 444px;
	border-radius: 12px;

	.background {
		padding: 16px 24px;

		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.modalHeader {
		background: #19943c;
	}

	.modalContent {
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.confirmation {
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: -0.011em;
			color: #0a0a0a;
			margin-bottom: 16px;
		}
		.link {
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: -0.011em;
			color: #424242;
		}
	}

	.modalFooter {
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background: #f5f5f5;
		.cancel {
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.011em;
			color: #0a0a0a;
			padding: 8px 10px;
			background: #ffffff;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
			border-radius: 8px;
			cursor: pointer;
			margin-right: 15px;
		}
		.deactivate {
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.011em;
			color: #ffffff;
			padding: 8px 12px;
			background: #19943c;
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
			border-radius: 8px;
			cursor: pointer;
			margin-left: 16px;
		}
	}
}

.linkDetails {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	display: flex;
	align-items: center;
	letter-spacing: -0.011em;

	/* Neutral/10 */

	color: #ffffff;
}

.businessForm {
	width: 100% !important;
	// margin-top: 24px;
	margin-right: auto;
	padding: 0 24px;
	& > form {
		display: flex;
		flex-direction: column;
		padding-top: 24px;
		padding-bottom: 24px;
		min-height: 360px;
		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}

.inputLabel {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: -0.006em;

	/* Neutral/90 */

	color: #424242;
}
