.routerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #f8f8f8;
	padding-bottom: 20px;
}

.switchContainer {
	margin: 0 40px;
	overflow-y: scroll;
}
.switchContainerP {
	margin: 0 15px;
	overflow-y: scroll;
}

.switchContainer::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.switchContainer {
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
}

.switchContainerP::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.switchContainerP {
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
}

.navContainer {
}

.wrapper {
	display: flex;
	position: relative;
	overflow-y: hidden;
	height: 100vh;
	width: 100%;
}

.sidedrawer {
	width: 250px;
	height: 100vh;
	border-right: 1px solid rgba(206, 206, 205, 0.4);
	overflow: hidden;
}

.sidedrawerSwitch {
	width: 75px;
	height: 100vh;
	border-right: 1px solid rgba(206, 206, 205, 0.4);
	overflow: hidden;
}
.sidedrawerColl {
	width: 78px;
	transition: all 250ms ease-in-out;
	overflow: hidden;
}

.newsidedrawer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
	background-color: #dfdfdf;
}

.tabContainer {
	margin: 32px 40px 24px 40px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 74px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.activeMenuItem {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	background-color: #fff;
	margin: 0 1rem;

	.activeItemColor {
		color: #0a0903;
		border-bottom: 3.5px solid #19943c;
		border-radius: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
	}
}
.subMenuItem {
	padding: 0.5rem;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #424242;
	margin-bottom: 5px;
}

.subMenuItem:hover {
	background: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}

// @media only screen and (max-width: 920px) {
// 	.switchContainer {
// 		margin-top: 1rem;
// 	}
// }
