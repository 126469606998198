.card {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px 16px 24px 16px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	height: 150px;
	// width: 300px !important;
	// margin: 0 20px;
}

.cardContent {
	padding-left: 12px;
	.cardCount {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: -0.011em;
		color: #424242;
	}
	.cardAmount {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
		color: #19943c;
		padding-top: 8px;
	}
	.cardAmountNgn {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
		color: #19943c;
		padding-top: 8px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		& span {
			font-family: 'HelveticaNeue';
			// font-style: normal;
			// font-weight: 500;
			// font-size: 15px;
			// line-height: 20px;
			// display: flex;
			// align-items: center;
			// letter-spacing: -0.006em;
			// color: #424242;
			padding-right: 4px;
		}
		.doubleZero {
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 500;
			align-self: flex-end;
			padding-bottom: 2px;
			font-size: 15px;
			line-height: 20px;
			display: flex;
			align-items: center;
			letter-spacing: -0.006em;
			color: #424242;
		}
	}

	.cardPercent {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 20px;
		.timeLine {
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 20px;
			display: flex;
			align-items: center;
			letter-spacing: -0.006em;
			color: #616161;
			padding-left: 8px;
		}
		.percentIncrease {
			background: #ebf5f0;
			border-radius: 400px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: fit-content;
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: -0.006em;
			color: #008243;
			height: 24px;
			padding: 4px 10px;
			& span {
				padding-left: 1.5px;
			}
		}

		.percentDecrease {
			background: #ffecec;
			border-radius: 400px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: fit-content;
			font-family: 'HelveticaNeue';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 20px;
			letter-spacing: -0.006em;
			color: #e00000;
			height: 24px;
			padding: 4px 10px;
		}
	}
}
