@import url('https://fonts.googleapis.com/css2?family=HelveticaNeue:wght@100;200;300;400;500;600;700;800&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: 'HelveticaNeue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 611px;
}

.check {
	display: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.pro-sidebar
	.pro-menu.shaped
	.pro-menu-item
	> .pro-inner-item
	> .pro-icon-wrapper {
	background-color: #f5f5f5 !important;
	padding: 0px;
}

.pro-sidebar
	.pro-menu
	.pro-menu-item
	> .pro-inner-item
	> .pro-icon-wrapper
	.pro-icon {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: flex-start !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
	position: relative;
	background-color: #f5f5f5 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
	position: relative;
	display: flex;
	align-items: center;
	padding: 8px 35px 8px 16px !important;
	cursor: pointer;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	z-index: 101;
	background: none !important;
	margin-top: 24px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
	flex-grow: 1;
	flex-shrink: 1;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;

	/* Input/Muted/Placeholder */

	color: #7a828a;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
	flex-grow: 1;
	flex-shrink: 1;

	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;

	/* Input/Muted/Placeholder */

	color: #7a828a;
}

.pro-sidebar .pro-menu a {
	text-decoration: none;
	color: #7a828a;
}

.pro-sidebar.collapsed
	.pro-menu
	> ul
	> .pro-menu-item.pro-sub-menu
	> .pro-inner-list-item
	> .popper-inner {
	max-height: 100vh;
	overflow-y: auto;
	background-color: #f5f5f5 !important;
	padding-left: 20px;
	border-radius: 4px;
}

.pro-sidebar > .pro-sidebar-inner {
	height: 100vh;
	z-index: 101;
	background: #f5f5f5 !important;
	position: relative !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	z-index: 101;
	position: relative !important;
}

/* ::-webkit-scrollbar {
  display: none !important;
} */

.pro-sidebar
	.pro-menu
	.pro-menu-item.pro-sub-menu
	> .pro-inner-item
	> .pro-arrow-wrapper
	.pro-arrow {
	display: inline-block;
	border-style: solid;
	border-color: #adadad;
	border-width: 0 2px 2px 0;
	padding: 2.5px;
	vertical-align: middle;
	transition: transform 0.3s;
	transform: rotate(45deg) !important;
}

.pro-sidebar
	.pro-menu
	.pro-menu-item.pro-sub-menu.open
	> .pro-inner-item
	> .pro-arrow-wrapper
	.pro-arrow {
	transform: rotate(225deg) !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
	padding-left: 0px !important;
}

.pro-sidebar .pro-menu a {
	text-decoration: none;
	color: #016e20 !important;
}

/* .recharts-surface {
  display: none !important;
}
.recharts-legend-icon {
  display: none !important;
} */

.recharts-legend-item {
	display: none !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
	color: #19943c !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
	color: #19943c !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	padding-right: 32px;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
}

.css-1rhbuit-multiValue {
	background-color: hsl(0, 0%, 90%);
	border-radius: 2px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 2px;
	min-width: 0;
	box-sizing: border-box;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	display: flex;
	align-items: center;
	letter-spacing: -0.011em;

	/* Neutral/90 */

	color: #424242;
}

.css-1s2u09g-control {
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsl(0, 0%, 100%);
	border-color: #424242;
	border-radius: 6px;
	border-style: solid;
	border-width: 1px;
	cursor: default;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	min-height: 38px;
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
	height: 50px;
}

.css-26l3qy-menu {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	position: absolute;
	top: 0;
	background-color: #fff;
	z-index: 10000000000000000;
	width: 100%;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height, or 150% */

	display: flex;
	align-items: center;
	letter-spacing: -0.011em;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}

.ant-menu-submenu-arrow {
	color: white !important;
}
.submenu-title:hover {
	color: white;
}
.ant-menu-submenu-selected {
	color: white;
}
.ant-menu-vertical {
	margin-left: -10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

/* .ant-menu-vertical .ant-menu-submenu-selected {
	color: white;
} */
.ant-menu-vertical .ant-menu-item-icon {
	width: 26px;
	height: 26px;
}

.ant-menu-sub.ant-menu-inline {
	background: #082b49;
}

.ant-menu-item a {
	color: white;
}

.ant-menu-vertical .ant-menu-item a {
	color: black;
}

.ant-menu-vertical .ant-menu-item-selected a {
	color: #1890ff;
}

.ant-menu-submenu-vertical {
	margin: 12px 0;
}

/* .ant-menu-submenu-vertical .ant-menu-submenu-active {
	color: #1890ff;
} */

/* .ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
	color: #ffffff;
} */

.drop {
	top: 30px !important;
}

/* .ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
	top: -7.41421356px;
} */

/* .ant-menu-submenu-popup > .ant-menu {
	background-color: #1990ff;
} */

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: 0px solid #f0f0f0;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: black;
}

.ant-checkbox-group-solid
	.ant-checkbox-button-wrapper-checked:not(
		.ant-checkbox-button-wrapper-disabled
	) {
	color: #fff;
	background: #19c03a;
	border-color: #fff;
}

.ant-checkbox-button-wrapper-checked:not(
		.ant-checkbox-button-wrapper-disabled
	):hover:before {
	background-color: #19c03a;
	outline: none;
}

.ant-checkbox-group-solid
	.ant-checkbox-button-wrapper-checked:not(
		.ant-checkbox-button-wrapper-disabled
	):hover {
	color: #fff;
	background: #19c03a;
	border-color: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #ffffff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #fff;
	border: 1px solid #eaeaec;
	border-radius: 15px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	height: 50px;
	padding: 11px 16px;
	background-color: #ffffff;
}
.MuiFormControl-root {
	width: 100%;
}
.MuiInputBase-root {
	height: 55px;
}

.selectOptions
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	position: relative;
	background: #ffffff;
	border: 1px solid rgba(43, 149, 88, 0.2);
	border-radius: 7px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.selectOptions
	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
	width: 100%;
	height: 54px;
	background-color: #ffffff;
	padding: 11px 16px;
}
