.modalwrapper {
	width: 489px;
	height: 312px;
	background: #ffffff;
}

.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}

.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}

.Approvebutton {
	width: 100%;
	height: 55px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	outline: none;
	border: none;
	margin-top: 60px;
}

.Declinebutton {
	width: 100%;
	height: 55px;
	background: #ea5851;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	outline: none;
	border: none;
	margin-top: 60px;
}

.Downloadbutton_span {
	margin-right: 8px;

	img {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}