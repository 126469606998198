.account {
	display: flex;
	align-items: center;
	justify-content: center;
}

.image {
	width: 40px;
}

.accountDetail {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	color: rgba(0, 40, 65, 0.8);
}

.buttonAction {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	border: 1px solid #19943c;
	// border: none;
	border-radius: 8px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #19943c;
	cursor: pointer !important;
	padding: 8px 12px;
}

.menuItem {
	margin: 0 0.5rem;
	padding: 0.5rem;
	display: flex;
}

.menuItem:hover {
	padding: 0.5rem;
	color: #0c4a1e;
	background-color: #ebf5f0;
}