
.tabContainer {
	margin: 32px 0px 24px 0px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 74px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.activeMenuItem {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	background-color: #fff;
	margin: 0 1rem;

	.activeItemColor {
		color: #0a0903;
		border-bottom: 3.5px solid #19943c;
		border-radius: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
	}
}
.subMenuItem {
	padding: 0.5rem;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #424242;
	margin-bottom: 5px;
}

.subMenuItem:hover {
	background: #edfaf1;
	border-radius: 6px;
	cursor: pointer;
}