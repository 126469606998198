.header {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.profile {
	width: 507px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-y: scroll;
	margin: 8px auto;
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
}

.input {
	height: 54px;
	background: #ffffff;
	border: 1px solid rgba(43, 149, 88, 0.2);
	border-radius: 7px;
	padding-left: 15px;
}

.profileCard {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: 24px;

	.logoText {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #0a0a0a;
	}

	.imagePlaceholder {
		text-align: center;
		height: 100px;
		width: 100px;
		margin-top: 8px;
		margin-bottom: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin: 8px auto 0 auto;
	}
}

.uploadButton {
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	padding: 8px 0px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin-top: 40px;
	cursor: pointer;

	& span {
		padding-left: 8px;
	}
}

.profileForm {
	width: 100% !important;
	margin-top: 20px;
	margin-right: auto;

	& > form {
		display: flex;
		flex-direction: column;

		& > div {
			&:not(:first-child) {
				margin-top: 27px;
			}
		}
	}
}

.form {
	width: 100%;
}

.subText {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

@media (max-width: 600px) {
	.profile {
		width: 100%;
	}

	.profileCard {
		width: 90vw;
	}
}
