.top_Container {
	width: 1380px;
	height: 165px;
	background: #ffffff;
	border-radius: 8px;
	padding: 30px 0px;
	display: flex;
	align-items: center;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
}
.top_Container_box {
	width: 266px;
	height: 105px;
	background: #ffffff;
	border: 0.5px solid rgba(206, 206, 205, 0.4);
	box-shadow: 0px 1px 2px rgba(0, 43, 18, 0.08);
	border-radius: 8px;
	margin-left: 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 20px;

	h5 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 16px;
		color: #4b5563;
		margin: 0;
		padding: 0;
	}
}
.top_Container_box_left {
	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;
		color: #19943c;
		margin: 0;
		padding: 0;
	}
}
.top_Container_box_leftdiv {
	display: flex;
	h4 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: rgba(10, 9, 3);
		margin: 0;
		padding: 0;
	}
	span {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		color: rgba(10, 9, 3, 0.5);
		margin: 0;
		padding: 0;
	}
}

.topTable {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 40px;
}

.detailsHeader {
	background: #ededed;
	border-radius: 8px 8px 0px 0px;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	padding: 10px 20px;
}

.tableContent {
	background: #ffffff;
	padding-left: 20px;
	border-radius: 0px 0px 8px 8px;
	filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
}

.customerInfo {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
	padding: 34px 0px;
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #5c5b57;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.transactionsHeader {
	font-family: 'HelveticaNeue';

	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
}
.transactionsHeaderflex {
	font-family: 'HelveticaNeue';
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
}

.detailBox {
	background: white;
	margin-bottom: 24px;
}

.detailh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #0a0903;
}
.detailBoxCorner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 24px;
}

.detailicon {
	cursor: pointer;
}

.accept {
	background: #f1f8f4;
	border-radius: 8px;
	padding: 20px;
	width: 65%;

	h4 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		color: #070602;
		margin: 0;
		padding: 0;
		margin-right: 5px;
	}

	h3 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #0a0903;
		margin: 0;
		padding: 0;
	}
}

.accept_top {
	display: flex;
	align-items: center;
}

.transactionsHeaderleft {
	display: flex;
	align-items: center;
	h3 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		display: flex;
		align-items: center;
		color: #0a0903;
		margin: 0;
		padding: 0;
		margin-right: 7px;
	}
}
.transactionsHeadercircle {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #19943c;
	background: rgba(170, 213, 188, 0.15);
	border-radius: 40px;
	padding: 5px;
}

.transactionsHeader_button {
	outline: none;
	border: none;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #ea5851;
	background: #f5ebeb;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	width: 187px;
	height: 36px;
}
