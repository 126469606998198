.menuBody {
  position: relative;
  .menuIcon {
    cursor: pointer;
  }
  .menuContent {
    background: #ffffff;
    border: 1px solid #ededed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 2px;
    position: absolute;
    z-index: 9;
    padding: 15px 10px 5px 15px;
    right: 2rem;
    width: 258px;
  }
}
