.menuSub {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  .optionName {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #424242;
    padding-left: 11px;
  }
}
