.week {
  background: #ffffff;
  border: 1px solid #19943c;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 40px;
  width: 110px;
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #424242;
  display: flex;
  align-items: center;
  justify-content: center;
  .dropArrow {
    padding-left: 4px;
  }
}

.exportMenu {
  position: relative;
}

.export {
  background: #ffffff;
  border: 1px solid #19943c;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 40px;
  width: 89px;
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #424242;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  .dropArrow {
    padding-left: 4px;
  }
}

.exportDropdown {
  width: 250px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
  right: 0rem;
  margin-top: 5px;
  position: absolute;
  z-index: 9;
}

.csv {
  padding-top: 16px;
  text-decoration: none;
  color: #424242;
}
