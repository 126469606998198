.textStyle {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	color: rgba(0, 40, 65, 0.8);
}

.username {
	font-size: 14px;
	line-height: 140%;
	color: #002841;
}

.usermail {
	font-size: 13px;
	line-height: 140%;
	color: rgba(0, 40, 65, 0.8);
}

.profile {
	display: flex;
	align-items: center;
	width: 100%;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #424242;
}

.profileDetail {
	color: rgba(0, 40, 65, 0.8);
	margin-left: 9px;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
}

.signOut {
	margin-left: 9px;
	color: #f71735;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
}

.logOut {
	display: flex;
	align-items: center;
	margin-top: 12px;
}

.userImage {
	border-radius: 50%;
	object-fit: cover;
}

.profileDetail {
	margin: 0.5rem 1rem;
	display: flex;
}

.profileName {
	margin-left: 1rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;

	.name {
		font-size: 16px;
		line-height: 24px;
	}

	.email {
		font-size: 12px;
		line-height: 16px;
	}

	.role {
		margin-top: 0.5rem;
		font-size: 14px;
		line-height: 20px;
	}
}
