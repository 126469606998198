.createLink {
  width: 216px;
  height: 40px;
  background: #19943c;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.011em;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  .link {
    margin-right: 10px;
  }
}

.filterSubheading {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #0a0a0a;
  cursor: pointer;
}

.filterBody {
  margin-top: 10px;
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #424242;
  cursor: pointer;
}
