.input {
	height: 54px;
	background: #ffffff;
	border: 1px solid rgba(43, 149, 88, 0.2);
	border-radius: 7px;
}

.modalwrapper {
	width: 518px;
	height: 574px;
	background: #ffffff;
	overflow-y: scroll;
}
.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}
.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}
.modalcontent {
	padding: 34px 32px;
	width: 100%;
}
.modalcontentp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #272501;
}
.modalcontentbox {
	margin-top: 29px;
	padding: 17px 30px;
	background: #f5faf7;
	border-radius: 5px;
	margin-bottom: 60px;
}
.modalcontentboxflex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.modalcontentboxp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #272501;
}

.cancel {
	width: 100%;
	height: 55px;
	background: #ffffff;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #19943c;
	outline: none;
	border: 0.5px solid gray;
	justify-content: center;
}

.submitHandler {
	width: 100%;
	height: 55px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #ffffff;
	outline: none;
	border: none;
	justify-content: center;

	&:disabled {
		background: gray;
	}
}

.ml {
	margin-left: 0.5rem;
}
