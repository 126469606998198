.dashbordContainer {
	margin: 10px 0;
	min-height: 100vh;
}

.filterSubheading {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}

.filterBody {
	margin-top: 10px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
}

.modalwrapper {
	width: 518px;
	// height: 574px;
	background: #ffffff;
}

.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}

.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}

.modalcontent {
	padding: 34px 32px;
	width: 100%;
}

.modalcontentp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #272501;
}

.modalcontentbox {
	margin-top: 29px;
	padding: 17px 30px;
	background: #f5faf7;
	border-radius: 5px;
	margin-bottom: 60px;
}

.modalcontentboxflex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modalcontentboxp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #272501;
}

.modalselect {
	width: 100%;
	margin-bottom: 21px;
}

.select {
	width: 100%;
}

.inputLabel {}

.modalFooter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 16px;
}

.cancel {
	width: 84px;
	height: 40px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #19943c;
	margin-right: 16px;
	outline: none;
	border: 1px solid rgb(238, 238, 238);
	background: #ffffff;
	border-radius: 4px;
}

.fund {
	padding: 11px 24px;
	height: 40px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;

	&:disabled {
		background: #6d7671;
	}
}

.input {
	width: 100%;
	margin-bottom: 29px;
}

.modalupload {
	height: 315px;
	background: #ffffff;
	border: 1px solid #2b9558;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	button {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #19943c;
		width: 160px;
		height: 40px;
		border: 1px solid #19943c;
		border-radius: 4px;
		margin-top: 50px;
		background-color: #ffffff;
	}

	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #4b492b;
		margin: 0;
		padding: 0;
		margin-top: 15px;
	}
}

.modaluploadp {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #09188b;
	margin-top: 28px;
	display: flex;
	justify-content: flex-start;
}

.createLink {
	width: 108px;
	height: 40px;
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #cce6d7;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	cursor: pointer;

	.link {
		margin-right: 10px;
	}
}

.transactionsHeader {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	margin-top: 38px;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-between;
}

.logdetails {
	width: 629px;
	height: 695px;
	margin: 0 auto;
	background: #ffffff;
	border: 1px solid rgba(206, 206, 205, 0.4);
	border-radius: 8px;
	overflow: hidden;
}

.logdetailscontainer {
	height: 650px;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;

	/* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
}

.logdetailswrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
}

.logdetailswraph3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.logdetailswraph4 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.logdetailswraph6 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: rgba(10, 9, 3, 0.5);
}

.checking {
	display: flex;
	align-items: center;

	p {
		margin: 0;
		padding: 0;
	}
}

.optionName {
	padding: 10px 0;
	font-family: 'HelveticaNeue';
	font-style: normal;
	cursor: pointer;
}

.businessNameh4 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #070602;
	margin-bottom: 28px;
}

.businessNameBox {
	padding: 14px 13px;
	height: 99px;
	background: #ffffff;
	border: 1px solid rgba(43, 149, 88, 0.2);
	border-radius: 7px;
	display: flex;
	align-items: flex-start;
	margin-bottom: 14px;
	color: #020201;
	cursor: pointer;
}

.boxright {
	flex: 1;

	h3 {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		margin: 0;
		padding: 0;
		margin-bottom: 8px;
	}

	p {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		margin: 0;
		padding: 0;
	}
}

.boxleft {
	margin-right: 13px;
}

.Downloadbutton {
	width: 100%;
	height: 55px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	outline: none;
	border: none;
	margin-top: 60px;

	&:disabled {
		opacity: 0.5;
	}
}

.Downloadbutton_faint {
	width: 100%;
	height: 55px;
	background: rgba(170, 213, 188, 0.3);
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #19943c;
	outline: none;
	border: none;
	margin-top: 60px;
}

.Downloadbutton_span {
	margin-right: 8px;

	img {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #5c5b57;
	margin-bottom: 8px;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #0a0903;
	opacity: 40%;
}


.failedChargebackWrapper {
	border: 1px solid #19943c;
	padding: 10px;
	overflow: auto;
}

.failedChargeback {
	// border: 1px solid grey;
	padding: 10px;
	margin-bottom: 10px;
}