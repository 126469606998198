.notificationBody {
	margin: 0 2rem;
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
}

.header {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.description {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: rgba(10, 9, 3, 0.6);
}

.contentBody {
	max-width: 795px;
	width: 100%;
	margin: 0 auto;
}

.controlButton {
	display: flex;
	margin-left: 1rem;
	padding: 0.2rem;
	cursor: pointer;
	margin-bottom: 14px;
}

.switchDiv {
	padding: 1rem;
}

.action {
	width: 136px;
	height: 36px;
	background: #ffffff;
	border: 1px solid rgba(206, 206, 205, 0.4);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #11110f;
	&:hover {
		background: rgba(170, 213, 188, 0.3);
		outline: none;
		border: none;
		color: #001a0b;
	}
}

.notificationbox {
	background: #ffffff;
	border: 1px solid rgba(206, 206, 205, 0.4);
	border-radius: 8px;
	padding: 30px;
	height: 392px;
}

.ml1 {
	margin-left: 1rem;
}

@media (max-width: 801px) {
	.controlButton {
		display: block;
	}

	.action {
		padding: 0;
	}

	.notificationBody {
		margin: 0;
	}
}
