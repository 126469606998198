.menuContent {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	flex-direction: row;
	cursor: pointer;

	.optionName {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #424242;
		padding-left: 11px;
	}
}

.createLink {
	width: fit-content;
	height: 40px;
	background: #19943c;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.011em;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	padding: 10px;
	cursor: pointer;

	.link {
		margin-right: 10px;
	}

	.mt1 {
		margin-top: 1rem;
	}
}

.mt0 {
	margin-top: 0.5rem;
}

.mt1 {
	margin-top: 1rem;
}

.dashbordContainer {
	margin: 0 5rem;
}

.dashboardChart {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	height: 100%;
	padding: 24px;
}

.filterSubheading {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
	cursor: pointer;
}

.filterBody {
	margin-top: 10px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #424242;
	cursor: pointer;
}

.mb {
	margin-bottom: 1rem;
}

.detailsHeader {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #616161;
}

.detailsSubHeader {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.011em;
	color: #0a0a0a;
}
.busiCompWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.busiCompWrapperImg {
	margin-right: 10px;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	background: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 12.95px;
		height: 15.22px;
	}
}

.busiCompWrapperp {
	margin: 0;
	padding: 0;
}

.Downloadbutton {
	width: 100%;
	height: 55px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	outline: none;
	border: none;
	margin-top: 60px;
}

.Downloadbutton_span {
	margin-right: 8px;

	img {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.customerInfoSingle {
	display: flex; 
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
}

.modalwrapper {
	width: 518px;
	min-height: 450px;
	background: #ffffff;
}
.modalhead {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 32px;
}
.modalheadh3 {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #1a1901;
}
.customerInfo {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
}

.detailsValue {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 16px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #5c5b57;
}

.detailsKey {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #0a0903;
}

.declinebutton {
	width: 100%;
	height: 55px;
	background: #f5ebeb;
	border: 0.5px solid #ea5851;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ea5851;
	outline: none;
	border: none;
}
.approvebutton {
	width: 100%;
	height: 55px;
	background: #19943c;
	border-radius: 4px;
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	outline: none;
	border: none;
}

@media only screen and (max-width: 600px) {
	.dashbordContainer {
		margin: 0.5rem 0;
	}
}
