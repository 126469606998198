.account {
	display: flex;
	align-items: center;
	justify-content: center;
}

.image {
	width: 40px;
}

.accountDetail {
	font-family: 'HelveticaNeue', sans-serif;
	font-style: normal;
	font-weight: 500;
	color: rgba(0, 40, 65, 0.8);
}

.menuItem {
	margin: 1rem 0.5rem;
	padding: 0.5rem;
	width: 300px;
	height: auto;
}

.notification {
	display: flex;
	align-items: center;
}
