.chartContent {
	font-family: 'HelveticaNeue';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	text-align: right;
	letter-spacing: -0.006em;

	/* Neutral/60 */

	color: #9e9e9e;
}
