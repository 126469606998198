.transactionContent {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.transactionDetails {
	display: flex;
	flex-direction: column;
		width: 100%;

	.transactions {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.011em;
		color: #000000;
		margin: 0;
		padding: 0;
	}
	.flow {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: rgba(10, 9, 3, 0.7);
		margin: 0;
		padding: 0;
		width: 100%;
	}
}

.timeLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.week {
		background: #ffffff;
		border: 1px solid #0069D0;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		height: 40px;
		width: 110px;
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		text-align: center;
		letter-spacing: -0.006em;
		color: #424242;
		display: flex;
		align-items: center;
		justify-content: center;
		.dropArrow {
			padding-left: 4px;
		}
	}
}

.total {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	margin: 30px 0px;

	.inflow {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: -0.006em;
		color: #616161;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.blue {
			width: 16px;
			height: 16px;
			background: #5FABFC;
			padding-right: 4px;
		}
		& span {
			padding-left: 4px;
		}
	}
	.outflow {
		font-family: 'HelveticaNeue';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		letter-spacing: -0.006em;
		color: #616161;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-left: 16px;
		.orange {
			width: 16px;
			height: 16px;
			background: #FDB62F;
			padding-right: 4px;
		}
		& span {
			padding-left: 4px;
		}
	}
}

.chartGraph {
	min-height: 500px;
	margin-top: 15px;
	background: #ffffff;
	border: 0.5px solid #cce6d7;
	border-radius: 5px;
	padding: 30px;
}
